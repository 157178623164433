@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    color-scheme: light dark;
}

html,
body {
    font-family: Roboto, sans-serif;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    scroll-behavior: smooth;
}

div#root {
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

code {
    background-color: rgba(150, 150, 150, 0.15);
    border-radius: 4px;
}
